.header {
  width: 100%;

  .logo {
    float: left;
    width: 200px;
    height: 100%;
    display: flex;
    align-items: center;
    > img {
      position: absolute;
      height: 40px;
    }
  }
  .accountAvatar {
    cursor: pointer;
  }
}
.signOut {
  width: 100%;
}
