.layout {
  min-height: 100vh !important;

  .content {
    display: flex;
    flex-direction: column;
    padding: 0 16px;

    .breadcrumb {
      padding: 8px 0;
    }
  }
  .mainContent {
    flex: auto;
    padding: 12px 24px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    > div {
      flex: 1;
    }
    :global(> .ant-card) {
      display: flex;
      flex-direction: column;
      :global(> .ant-card-head) {
        padding: 0;
      }
      :global(> .ant-card-body) {
        position: relative;
        flex: 1;
        > div {
          overflow-y: auto;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }
        :global(.ant-pagination) {
          margin: 16px 0;
        }
      }
    }
  }

  .footer {
    padding: 8px;
    text-align: center;
  }
}
