.form {
  > div:nth-last-child(2) {
    margin-bottom: 24px !important;
  }
  > div {
    margin-bottom: 16px !important;
    > div:first-of-type {
      padding: 5px !important;
      font-weight: 500;
    }
  }
}

.actionWrapper {
  display: flex !important;
  align-items: flex-start;
  justify-content: flex-end;
}

.collapseHeader > div {
  align-items: center !important;
  > span {
    font-size: 15px;
    font-weight: bold;
    :global(.ant-space-item) {
      display: flex;
    }
  }
}
