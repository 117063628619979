.title {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}

.section {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 7px;
  background-color: #fafafa;
}
.form {
  > div:nth-last-child(2) {
    margin-bottom: 24px !important;
  }
  > div {
    margin-bottom: 16px !important;
    > div:first-of-type {
      padding: 5px !important;
      font-weight: 500;
    }
  }
}


.loaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
