body {
  width: 100vw;
  height: 100vh;

  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  font-family: "Work Sans", sans-serif;
}

svg {
  fill: currentColor;
}

.fullPageLoader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  border: solid 1px;
  background-color: white;
}
::-webkit-scrollbar-thumb {
  background: #8e8e8e6b;
  border-radius: 8px;
  cursor: pointer;
}
::-webkit-scrollbar-track {
  background: white;
}
